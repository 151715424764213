<template>
  <div>
    <div style="clear: both">
      <a-row class="mb-10" :gutter="[20, 0]">
        <a-col :span="24">
          <h3 class="title">
            <a-row>
              <a-col :span="16">
                TRECHO {{ flightID }}
                <a-form-item class="section-type ml-20">
                  <a-radio-group
                    :options="[
                      { label: 'Ida', value: 'Ida' },
                      { label: 'Volta', value: 'Volta' },
                      {
                        label: 'Conexão',
                        value: 'Conexão',
                      },
                    ]"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_type`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col class="a-right" :span="8">
                <a-button
                  v-if="add && totalRows < 8"
                  size="small"
                  @click="addRow"
                  type="link"
                >
                  Adicionar
                </a-button>
                <a-button
                  class="red"
                  v-if="id == totalRows && totalRows > 1"
                  size="small"
                  @click="removeRow"
                  type="link"
                >
                  Remover
                </a-button>
              </a-col>
            </a-row>
          </h3>
        </a-col>

        <div style="clear: both"></div>

        <a-row :gutter="[20, 0]" style="padding: 0 10px">
          <a-col :span="4">
            <a-form-item>
              <label
                :class="
                  this.form.getFieldValue(
                    `flight_${flightSectionID}_section_${flightID}_origin`
                  )
                    ? 'filled'
                    : ''
                "
                >Origem</label
              >

              <a-auto-complete
                placeholder="Origem"
                option-label-prop="value"
                :dropdown-style="{ width: '500px' }"
                v-decorator="[
                  `flight_${flightSectionID}_section_${flightID}_origin`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                allowClear
                @change="search"
              >
                <template slot="dataSource">
                  <a-select-option
                    class="dropdown-airports"
                    v-for="airport in filteredAirports"
                    :key="airport.sigla"
                    :value="airport.sigla"
                    @click="clearAirports"
                  >
                    {{ airport.sigla }}
                    <a-divider type="vertical" />
                    {{ airport.nomeaeroporto }}
                  </a-select-option>
                </template>
                <a-input
                  maxLength="3"
                  class="travel-input"
                  placeholder="Origem"
                >
                  <a-icon slot="prefix" type="field-svg" />
                  <!-- <a-icon slot="suffix" type="loading" /> -->
                </a-input>
              </a-auto-complete>
            </a-form-item>

            <a-form-item>
              <label
                :class="
                  this.form.getFieldValue(
                    `flight_${flightSectionID}_section_${flightID}_destination`
                  )
                    ? 'filled'
                    : ''
                "
                >Destino</label
              >

              <a-auto-complete
                placeholder="Destino"
                option-label-prop="value"
                :dropdown-style="{ width: '500px' }"
                v-decorator="[
                  `flight_${flightSectionID}_section_${flightID}_destination`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                allowClear
                @change="search"
              >
                <template slot="dataSource">
                  <a-select-option
                    class="dropdown-airports"
                    v-for="airport in filteredAirports"
                    :key="airport.sigla"
                    :value="airport.sigla"
                    @click="clearAirports"
                  >
                    {{ airport.sigla }}
                    <a-divider type="vertical" />
                    {{ airport.nomeaeroporto }}
                  </a-select-option>
                </template>
                <a-input
                  maxLength="3"
                  class="travel-input"
                  placeholder="Destino"
                >
                  <a-icon slot="prefix" type="field-svg" />
                  <!-- <a-icon slot="suffix" type="loading" /> -->
                </a-input>
              </a-auto-complete>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-row :gutter="10">
              <a-col :span="14">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_departure_date`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Data de embarque</label
                  >
                  <a-date-picker
                    class="travel-input"
                    placeholder="Embarque"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    v-mask="'##/##/####'"
                    @change="calcDuration"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_departure_date`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
              <a-col :span="10">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_departure_time`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Horário embarque</label
                  >
                  <a-input
                    class="travel-input"
                    placeholder="Horário"
                    @change="calcDuration"
                    v-mask="'##:##'"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_departure_time`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="10">
              <a-col :span="14">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_arrival_date`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Data de desembarque</label
                  >
                  <a-date-picker
                    class="travel-input"
                    placeholder="Desembarque"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    v-mask="'##/##/####'"
                    @change="calcDuration"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_arrival_date`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
              <a-col :span="10">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_arrival_time`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Horário desembarque</label
                  >
                  <a-input
                    class="travel-input"
                    placeholder="Horário"
                    @change="calcDuration"
                    v-mask="'##:##'"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_arrival_time`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <label
                :class="
                  this.form.getFieldValue(
                    `flight_${flightSectionID}_section_${flightID}_class`
                  )
                    ? 'filled'
                    : ''
                "
                >Classe</label
              >

              <a-select
                class="travel-input"
                show-search
                placeholder="Classe"
                v-decorator="[
                  `flight_${flightSectionID}_section_${flightID}_class`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of flightClasses"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="travel-input">
              <label
                :class="
                  this.form.getFieldValue(
                    `flight_${flightSectionID}_section_${flightID}_duration`
                  )
                    ? 'filled'
                    : ''
                "
                >Duração do vôo</label
              >
              <a-input
                class="readonly"
                placeholder="Duração vôo"
                v-mask="'##:##'"
                v-decorator="[
                  `flight_${flightSectionID}_section_${flightID}_duration`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Modifique as datas.',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item>
              <label
                :class="
                  this.form.getFieldValue(
                    `flight_${flightSectionID}_section_${flightID}_airline`
                  )
                    ? 'filled'
                    : ''
                "
                >Companhia aérea</label
              >

              <a-select
                class="travel-input"
                style="width: 100%"
                placeholder="Companhia aérea"
                show-search
                :allow-clear="true"
                v-decorator="[
                  `flight_${flightSectionID}_section_${flightID}_airline`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="airline in airlinesList"
                  :key="airline.id"
                  :value="airline.name"
                >
                  <img v-if="airline.logo" :src="airline.logo" width="13" />
                  {{ airline.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-row :gutter="10">
              <a-col :span="12">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_number`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Nº do vôo</label
                  >
                  <a-input
                    type="number"
                    class="travel-input"
                    placeholder="Nº do vôo"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_number`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item>
                  <label
                    :class="
                      this.form.getFieldValue(
                        `flight_${flightSectionID}_section_${flightID}_stops`
                      )
                        ? 'filled'
                        : ''
                    "
                    >Paradas</label
                  >
                  <a-input
                    type="number"
                    class="travel-input"
                    placeholder="Paradas"
                    v-decorator="[
                      `flight_${flightSectionID}_section_${flightID}_stops`,
                      {
                        rules: [
                          {
                            required: dynamicRequiredField(),
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Airports from "@/mixins/internal-apis/airports.json";
import { format, parse, intervalToDuration } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "FlightForm",
  mixins: [formatThings],
  props: {
    id: Number,
    add: Boolean,
    totalRows: Number,
    flightRows: Array,
    contract: Object,
    form: Object,
    flightSection: Object,
    flightSectionID: Number,
    airlinesList: Array,
  },
  data() {
    return {
      ciaLoading: false,
      flightID: this.flightRows[this.id - 1].id,
      flightIndex: this.id - 1,
      flightClasses: [
        { name: "ECONÔMICA", value: "ECONÔMICA" },
        { name: "ECONÔMICA PREMIUM", value: "ECONÔMICA PREMIUM" },
        { name: "EXECUTIVA", value: "EXECUTIVA" },
        { name: "FIRST CLASS", value: "FIRST CLASS" },
      ],
      airports: Airports,
      filteredAirports: [],
      listCias: [],
    };
  },
  beforeMount() {},
  methods: {
    calcDuration() {
      setTimeout(() => {
        let departureDate =
          this.contract[
            `flight_${this.flightSectionID}_section_${this.id}_departure_date`
          ];

        if (typeof departureDate == "string") {
          departureDate = departureDate.split("T")[0];
        }

        if (typeof departureDate == "object") {
          parse;
          departureDate = format(departureDate._d, "yyyy-MM-dd");
        }

        let departureTime =
          this.contract[
            `flight_${this.flightSectionID}_section_${this.id}_departure_time`
          ];

        let arrivalDate =
          this.contract[
            `flight_${this.flightSectionID}_section_${this.id}_arrival_date`
          ];

        if (typeof arrivalDate == "string") {
          arrivalDate = arrivalDate.split("T")[0];
        }

        if (typeof arrivalDate == "object") {
          arrivalDate = format(arrivalDate._d, "yyyy-MM-dd");
        }

        let arrivalTime =
          this.contract[
            `flight_${this.flightSectionID}_section_${this.id}_arrival_time`
          ];

        //

        if (departureDate && departureTime && arrivalDate && arrivalTime) {
          let interval = intervalToDuration({
            start: new Date(departureDate + " " + departureTime),
            end: new Date(arrivalDate + " " + arrivalTime),
          });

          this.form.setFieldsValue({
            [`flight_${this.flightSectionID}_section_${this.id}_duration`]: `${this.formatDateTimeZeros(
              interval.hours
            )}:${this.formatDateTimeZeros(interval.minutes)}`,
          });
        }
      }, 20);
    },

    removeRow() {
      this.$emit("removeFlightRows", this.id, this.flightSectionID);
    },
    addRow() {
      this.$emit(
        "updateFlightRows",
        this.flightRows.length,
        this.flightSectionID
      );
    },
    search: _.debounce(function (e) {
      if (e.length > 1) {
        this.filteredAirports = this.airports.filter((item) => {
          return item.sigla.includes(e.toUpperCase());
        });
      }
    }, 600),

    clearAirports(val) {
      val;
      setTimeout(() => {
        this.filteredAirports = [];
      }, 1000);
    },
    clearAirlines(val) {
      val;
      setTimeout(() => {
        this.listCias = [];
      }, 1000);
    },
    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form
          .getFieldValue("contracted_services")
          .includes("flight");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>
