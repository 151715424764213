var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"clear":"both"}},[_c('a-row',{staticClass:"mb-10",attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":16}},[_vm._v("\n              TRECHO "+_vm._s(_vm.flightID)+"\n              "),_c('a-form-item',{staticClass:"section-type ml-20"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_type`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_type`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"options":[
                    { label: 'Ida', value: 'Ida' },
                    { label: 'Volta', value: 'Volta' },
                    {
                      label: 'Conexão',
                      value: 'Conexão',
                    },
                  ]}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":8}},[(_vm.add && _vm.totalRows < 8)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":_vm.addRow}},[_vm._v("\n                Adicionar\n              ")]):_vm._e(),_vm._v(" "),(_vm.id == _vm.totalRows && _vm.totalRows > 1)?_c('a-button',{staticClass:"red",attrs:{"size":"small","type":"link"},on:{"click":_vm.removeRow}},[_vm._v("\n                Remover\n              ")]):_vm._e()],1)],1)],1)]),_vm._v(" "),_c('div',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('a-row',{staticStyle:{"padding":"0 10px"},attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_section_${_vm.flightID}_origin`
                )
                  ? 'filled'
                  : ''},[_vm._v("Origem")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_section_${_vm.flightID}_origin`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_section_${flightID}_origin`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Origem","option-label-prop":"value","dropdown-style":{ width: '500px' },"allowClear":""},on:{"change":_vm.search}},[_c('template',{slot:"dataSource"},_vm._l((_vm.filteredAirports),function(airport){return _c('a-select-option',{key:airport.sigla,staticClass:"dropdown-airports",attrs:{"value":airport.sigla},on:{"click":_vm.clearAirports}},[_vm._v("\n                  "+_vm._s(airport.sigla)+"\n                  "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n                  "+_vm._s(airport.nomeaeroporto)+"\n                ")],1)}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"maxLength":"3","placeholder":"Origem"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],2)],1),_vm._v(" "),_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_section_${_vm.flightID}_destination`
                )
                  ? 'filled'
                  : ''},[_vm._v("Destino")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_section_${_vm.flightID}_destination`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_section_${flightID}_destination`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Destino","option-label-prop":"value","dropdown-style":{ width: '500px' },"allowClear":""},on:{"change":_vm.search}},[_c('template',{slot:"dataSource"},_vm._l((_vm.filteredAirports),function(airport){return _c('a-select-option',{key:airport.sigla,staticClass:"dropdown-airports",attrs:{"value":airport.sigla},on:{"click":_vm.clearAirports}},[_vm._v("\n                  "+_vm._s(airport.sigla)+"\n                  "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n                  "+_vm._s(airport.nomeaeroporto)+"\n                ")],1)}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"maxLength":"3","placeholder":"Destino"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],2)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-row',{attrs:{"gutter":10}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_departure_date`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Data de embarque")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_departure_date`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_departure_date`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Embarque","format":"DD/MM/YYYY","showToday":false},on:{"change":_vm.calcDuration}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_departure_time`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Horário embarque")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_departure_time`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_departure_time`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Horário"},on:{"change":_vm.calcDuration}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_c('a-row',{attrs:{"gutter":10}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_arrival_date`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Data de desembarque")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_arrival_date`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_arrival_date`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Desembarque","format":"DD/MM/YYYY","showToday":false},on:{"change":_vm.calcDuration}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_arrival_time`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Horário desembarque")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_arrival_time`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_arrival_time`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Horário"},on:{"change":_vm.calcDuration}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_section_${_vm.flightID}_class`
                )
                  ? 'filled'
                  : ''},[_vm._v("Classe")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_section_${_vm.flightID}_class`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_section_${flightID}_class`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Classe"}},_vm._l((_vm.flightClasses),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n                "+_vm._s(item.name.toUpperCase())+"\n              ")])}),1)],1),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:this.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_section_${_vm.flightID}_duration`
                )
                  ? 'filled'
                  : ''},[_vm._v("Duração do vôo")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"},{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_section_${_vm.flightID}_duration`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Modifique as datas.',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_section_${flightID}_duration`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Modifique as datas.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"readonly",attrs:{"placeholder":"Duração vôo"}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_section_${_vm.flightID}_airline`
                )
                  ? 'filled'
                  : ''},[_vm._v("Companhia aérea")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_section_${_vm.flightID}_airline`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_section_${flightID}_airline`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Companhia aérea","show-search":"","allow-clear":true}},_vm._l((_vm.airlinesList),function(airline){return _c('a-select-option',{key:airline.id,attrs:{"value":airline.name}},[(airline.logo)?_c('img',{attrs:{"src":airline.logo,"width":"13"}}):_vm._e(),_vm._v("\n                "+_vm._s(airline.name)+"\n              ")])}),1)],1),_vm._v(" "),_c('a-row',{attrs:{"gutter":10}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_number`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Nº do vôo")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_number`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_number`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Nº do vôo"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(
                      `flight_${_vm.flightSectionID}_section_${_vm.flightID}_stops`
                    )
                      ? 'filled'
                      : ''},[_vm._v("Paradas")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `flight_${_vm.flightSectionID}_section_${_vm.flightID}_stops`,
                    {
                      rules: [
                        {
                          required: _vm.dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `flight_${flightSectionID}_section_${flightID}_stops`,\n                    {\n                      rules: [\n                        {\n                          required: dynamicRequiredField(),\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Paradas"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }